import React, { useState, useCallback } from 'react';
import { Header } from './components/Header';
import { statisticServices } from '../../services/statistic';
import Highcharts from 'highcharts';
import { openWarningNotification } from '../../utils/notifications';
import { Spin } from 'antd';

const defaultOptions = {
  chart: {
    zoomType: 'x'
  },
  title: {
    text: ''
  },
  xAxis: [
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      }
    },
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      },
      opposite: true
    }
  ],
  tooltip: {
    shared: true
  },
  legend: {
    floating: false,
    layout: 'vertical',
    navigation: {
      activeColor: '#3E576F',
      animation: true,
      arrowSize: 3,
      inactiveColor: '#CCC',
      style: {
        fontWeight: 'bold',
        color: '#333',
        fontSize: '12px'
      }
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return Highcharts.dateFormat('%e.%m.%Y', new Date(min)) + ' - ' + Highcharts.dateFormat('%e.%m.%Y', new Date(max)) + ' ' + this.name;
    },
    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
  }
};

export const ChartView = () => {
  const [loading, setLoading] = useState(false);

  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    var text;
    switch (name) {
      case 'im':
        text = 'impressions';
        break;
      case 'dw':
        text = 'downloads';
        break;
      case 'pl':
        text = 'leads';
        break;
      case 'ri':
        text = 'redirect impressions';
        break;
      case 'pf':
        text = 'fired';
        break;
      case 'p1':
        text = 'PX 1';
        break;
      case 'cr':
        text = 'conversion rate';
        break;
      case 'p2':
        text = 'PX 2';
        break;
      default:
        text = name;
    }
    if (index > options.yAxis.length - 1) {
      let format = '{value}';
      if (name === 'spent' || name === 'earned') {
        format = '$ ' + format;
      }

      const colorIndex = options.series.length;
      const color = Highcharts.getOptions().colors[colorIndex];

      options.yAxis.push({
        labels: {
          format,
          style: {
            color
          }
        },
        title: {
          text: text,
          style: {
            color
          }
        },
        opposite: true
      });
    }

    options.series.push({
      name: text,
      type: 'spline',
      xAxis,
      yAxis: index,
      data
    });
  }, []);

  const getChartData = async (filters) => {
    setLoading(true);
    let options = { ...defaultOptions, series: [], yAxis: [] };
    let yAxisIndex = 0;
    Highcharts.chart('chart-wrapper', options);

    const promises = filters.lines.map(async (line, index) => {
      const chartData = await statisticServices.channelChart(line, filters.perHour, filters.dateRange, filters.includeErrors);
      let compareResults = '';
      if (filters.compareDateRange.from && filters.compareDateRange.to) {
        compareResults = await statisticServices.channelChart(line, filters.perHour, filters.compareDateRange, filters.includeErrors);
      }
      if (chartData && chartData.values) {
        if (line.metrics.length > 0) {
          const metrics = line.metrics;
          metrics.forEach((metric, metricIndex) => {
            const values = chartData.values.map((value) => [value[0], value[metricIndex + 1]]);
            addNewYAxis(metric, values, yAxisIndex, 0, options);
            if (compareResults && compareResults.values) {
              const values = compareResults.values.map((value) => [value[0], value[metricIndex + 1]]);
              addNewYAxis(metric, values, yAxisIndex, 1, options);
            }
            yAxisIndex++;
            if (yAxisIndex > options.yAxis.length) {
              yAxisIndex = options.yAxis.length;
            }
          });
        }
      } else {
        openWarningNotification({ message: `There are no data for this filters on line ${index + 1}`, duration: 8 });
      }
    });
    await Promise.all(promises);
    if (options.series.length > 0) {
      Highcharts.chart('chart-wrapper', options);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Channel Chart</h1>
      <Header
        onSubmit={async (value) => {
          await getChartData(value);
        }}
        loading={loading}
      />
      <Spin tip="Loading..." spinning={loading}>
        <div id="chart-wrapper" style={{ height: '500px' }} />
      </Spin>
    </div>
  );
};
