import { Col, Tooltip, Row, Spin, Modal, Select, Checkbox, Divider } from 'antd';
import React, { useState } from 'react';

import { DeleteOutlined, PlayCircleOutlined, ToolOutlined } from '@ant-design/icons';
import { statisticServices } from '../../../services/statistic';
import { showApiErrors } from '../../../utils/showApiErrors';
import { SaveAsOrEditPresetModal } from './SaveAsOrEditPresetModal';
import { openSuccessNotification } from '../../../utils/notifications';
import { auth } from '../../../services/auth';
import { useUpdate } from 'react-use';

export const PresetActions = ({ id, creator, onModalOpen, onPresetDelete, onSetDefault, selectedValue }) => {
  const [settingDefault, setSettingDefault] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSetDefault = async () => {
    try {
      setSettingDefault(true);
      await statisticServices.setDefaultChannelStatsPreset(id);
      openSuccessNotification({ message: 'Successfully set default preset!' });
      await auth.getUserProfile(true);
      onSetDefault();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSettingDefault(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await statisticServices.deleteChannelStatsPreset(id);
      openSuccessNotification({ message: 'Successfully deleted preset!' });
      onPresetDelete();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setDeleting(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Are you sure you want to delete preset?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDelete,
      onCancel() {}
    });
  };

  return (
    <Row gutter={4}>
      <Col span={8}>
        {auth.user?.default_preset_id !== id && (
          <Tooltip title="Set default">
            {settingDefault ? (
              <Spin size="small" />
            ) : (
              <PlayCircleOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetDefault();
                }}
              />
            )}
          </Tooltip>
        )}
      </Col>

      <Col span={8}>
        {statisticServices.hasPresetEditPermission(creator) && (
          <Tooltip title="Rename/Assign">
            <ToolOutlined
              onClick={(e) => {
                e.stopPropagation();
                onModalOpen();
              }}
            />
          </Tooltip>
        )}
      </Col>

      <Col span={8}>
        {statisticServices.hasPresetEditPermission(creator) && selectedValue !== id && (
          <Tooltip title="Delete">
            {deleting ? (
              <Spin size="small" />
            ) : (
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDelete();
                }}
              />
            )}
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

const PresetOption = ({ option, onEditModalOpen, onPresetDelete, onSetDefault, selectedValue }) => {
  const { value, label, creator } = option;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{label}</div>
      <div style={{ minWidth: '55px', paddingLeft: '10px' }}>
        <PresetActions
          id={value}
          creator={creator}
          onModalOpen={() => onEditModalOpen(value)}
          onPresetDelete={onPresetDelete}
          onSetDefault={onSetDefault}
          selectedValue={selectedValue}
        />
      </div>
    </div>
  );
};

export const PresetSelect = ({ options, loading, selectValue, onPresetEdit, onPresetDelete, onChange = () => {} }) => {
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [editPresetId, setEditPresetId] = useState(selectValue);
  const [showOnlyMine, setShowOnlyMine] = useState(true);
  const update = useUpdate();

  const handleCloseModal = () => {
    setVisibleEditModal(false);
    onPresetEdit();
  };

  const dropdownOptions =
    statisticServices.canViewAllPresets() && showOnlyMine
      ? options.filter((o) => o.value === selectValue || statisticServices.isOwnPreset(o))
      : options;

  return (
    <>
      <Select
        style={{ width: '100%' }}
        loading={loading}
        placeholder="Select preset"
        value={selectValue}
        onChange={(value) => onChange(value)}
        optionLabelProp="label"
        optionFilterProp="data-searchvalue"
        dropdownMatchSelectWidth={false}
        allowClear
        showSearch
        dropdownRender={(menu) => (
          <>
            {statisticServices.canViewAllPresets() && (
              <>
                <Checkbox style={{ marginLeft: '10px' }} checked={showOnlyMine} onChange={(e) => setShowOnlyMine(e.target.checked)}>
                  Show only mine
                </Checkbox>
                <Divider style={{ margin: '8px 0' }} />
              </>
            )}
            {menu}
          </>
        )}
      >
        {dropdownOptions.map((option) => {
          return (
            <Select.Option value={option.value} label={option.label} key={option.value} data-searchvalue={option.value + option.label}>
              <PresetOption
                selectedValue={selectValue}
                onEditModalOpen={(id) => {
                  setEditPresetId(id);
                  setVisibleEditModal(true);
                }}
                onPresetDelete={onPresetDelete}
                onSetDefault={update}
                option={option}
              />
            </Select.Option>
          );
        })}
      </Select>
      <SaveAsOrEditPresetModal id={editPresetId} visible={visibleEditModal} onSuccess={handleCloseModal} onCancel={handleCloseModal} />
    </>
  );
};
