import React from 'react';

import { openErrorNotification } from './notifications';

const generateMessageHelper = ({ key, level, delimiter, message }) => (
  <div key={key} style={{ marginLeft: level * 10 }}>
    {key}
    {delimiter}
    {message}
  </div>
);

const generateMessage = (content, level = 0) => {
  const message = [];

  Object.keys(content).forEach((key) => {
    if (Array.isArray(content[key]) && content[key].every((item) => typeof item === 'string')) {
      message.push(generateMessageHelper({ key, level, delimiter: ' - ', message: content[key].join(',') }));
    } else if (typeof content[key] === 'object') {
      // Also handles array of objects
      message.push(generateMessageHelper({ key, level, delimiter: ' - ', message: generateMessage(content[key], level + 1) }));
    } else {
      message.push(generateMessageHelper({ key, level, delimiter: ' - ', message: content[key] }));
    }
  });

  return message;
};

export const showApiErrors = (error) => {
  let message = '';
  let additionalData = '';

  if (error && error.response) {
    if (error.response.data) {
      if (error.response.data.code === 'validation_failed') {
        const details = error.response.data.detail;
        message = generateMessage(details);
      } else {
        message = error.response.data.message;
        additionalData = error.response.data.data;
      }
    }
  }

  openErrorNotification({
    message: error.response ? (
      <>
        <div>Request failed with status code {error.response.status}</div> <div>{message}</div>
        {additionalData ? <div>{JSON.stringify(additionalData)}</div> : null}
      </>
    ) : (
      <>
        <div>Something went wrong</div> {error.stack}
      </>
    ),
    duration: 8
  });
};
